// Instrument
import { types } from './types';
import { types as authTypes } from '../auth/types';
import { isNil } from 'ramda';
import update from 'immutability-helper';

export const initialState = {
    details:              {},
    balance:              {},
    ipDetails:            {},
    proposal:             {},
    activeSub:            {},
    activeSubAccount:     null,
    activeBloggingSub:    {},
    activeDesignSub:      {},
    activeWebBuildingSub: {},
    activeAssistantsSub:  {},
    activeBundle:         {},
    activeBundleAccount:  null,
    activeBrandProfile:   null,
    brandProfiles: [],
    company: {
        branding: {
            company_name: '',
            white_label: false,
            domain_name: '',
            header_logo: {
                file_id: null,
                url: '',
            },
            favicon: {
                file_id: null,
                url: '',
            },
            brand_color: null,
            host:        null,
        },
        billing: {
            currency: '',
            billing_company_name: '',
            billing_tax_number: '',
            billing_address: '',
            billing_city: '',
            billing_state: '',
            billing_zip: '',
            billing_country_code: '',
        },
        subscriptions: null,
    },
    talents: {
        copywriters: [],
        designers: [],
        suggested: {
            copywriters: [],
            designers: [],
        },
        blacklist: {
            copywriters: [],
            designers: [],
        }
    },
    currentUser: {},
    currentAccount: {},
    paymentCards:         {
        stripe: null,
        epiic: null,
    },
    prepayAmount: null,
    prepayBankActiveAccount: null
};

export const userReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        /* NEW */
        case types.SET_USER_STATE:
            return Object.assign({}, state, {
                [payload.prop]: payload.data,
            });
        case types.SET_USER_COMPANY_STATE:
            return Object.assign({}, state, {
                company: {
                    ...state.company,
                    [payload.prop]: payload.data,
                },
            });

        /* Form & ui */
        case types.UPDATE_ORDER_COUNT:
            const accounts = state.details.accounts.filter(o => o.account_id !== payload.account);
            const account = state.details.accounts.filter(o => o.account_id === payload.account)[0];

            return Object.assign({}, state, {
                details: {
                    ...state.details,
                    accounts: [ ...accounts, {
                        ...account,
                        order_count: payload.order_count,
                    }],
                },
            });

        /* Copywriters */
        case types.ADD_COPYWRITER_TO_BLACKLIST:
            const activeTalents = state.talents[payload.type].filter(o => o.writer_uid !== payload.writer_uid );
            const disabledTalent = state.talents[payload.type].filter(o => o.writer_uid === payload.writer_uid );

            return update(state, {
                talents: {
                    [payload.type]: {$set: activeTalents},
                    blacklist: {
                        [payload.type]: {$push: disabledTalent},
                    }
                }
            });

        case types.ADD_COPYWRITER_TO_ACCOUNT:
            const disabledTalents = state.talents.blacklist[payload.type].filter(o => o.writer_uid !== payload.writer_uid );
            const activeTalent = state.talents.blacklist[payload.type].filter(o => o.writer_uid === payload.writer_uid );

            return update(state, {
                talents: {
                    [payload.type]: {$push: activeTalent},
                    blacklist: {
                        [payload.type]: {$set: disabledTalents},
                    }
                }
            });

        case types.ADD_SUGGESTED_COPYWRITER_TO_ACCOUNT:
            const suggestedTalents = state.talents.suggested[payload.type].filter(o => o.writer_uid !== payload.writer_uid );
            const suggestedTalent = state.talents.suggested[payload.type].filter(o => o.writer_uid === payload.writer_uid );
            const blacklist = state.talents.blacklist[payload.type].filter(o => o.writer_uid !== payload.writer_uid );

            return update(state, {
                talents: {
                    [payload.type]: {$push: suggestedTalent},
                    suggested: {
                        [payload.type]: {$set: suggestedTalents},
                    },
                    blacklist: {
                        [payload.type]: {$set: blacklist},
                    }
                }
            });

        case types.UPDATE_COPYWRITER_SUCCESS:
            const ids = payload.map(({writer_uid}) => writer_uid);
            const talents = state.talents[payload.type].filter((o) => !ids.includes(o.writer_uid));

            return update(state, {
                talents: {
                    [payload.type]: {$push: [ ...talents, ...payload ]},
                }
            });

        case types.UPDATE_COPYWRITER_STATUS:
            const c = state.talents[payload.type].findIndex(o => o.writer_uid === payload.writer_uid);

            return update(state, {
                talents: {
                    [payload.type]: {
                        [c]: {
                            status: {$set: payload.status}
                        }
                    },
                }
            });

        case types.UPDATE_COPYWRITER_NOTES:
            const n = state.talents[payload.type].findIndex(o => o.writer_uid === payload.writer_uid);

            return update(state, {
                talents: {
                    [payload.type]: {
                        [n]: {
                            notes: {$set: payload.notes}
                        }
                    },
                }
            });

        /* Accounts */
        case types.CREATE_ACCOUNT_SUCCESS:
            return update(state, {
                details: {
                    accounts: {$push: [{
                            account_name: payload.account_name,
                            account_id: payload.account_uid,
                            account_currency: payload.account_currency,
                            order_count: payload.order_count,
                            logo_url: payload.logo_url,
                            own_balance: payload.own_balance,
                            publications: isNil(payload.publications) ? [] : payload.publications,
                        }]},
                },
            });

        case types.DELETE_ACCOUNT_SUCCESS:
            return Object.assign({}, state, {
                details: {
                    ...state.details,
                    accounts: state.details.accounts.filter(o => o.account_id !== payload),
                }
            });

        case types.UPDATE_ACCOUNT_SUCCESS:
            const updatedAccount = { ...payload };
            const { account_uid: account_id, account_name, logo_url } = updatedAccount;
            Object.keys(updatedAccount).forEach(key => {
                if ( updatedAccount[key] === null ) {
                    delete updatedAccount[key];
                }
            });
            const i = state.details.accounts.findIndex(o => o.account_id === account_id);
            return update(state, {
                details: {
                    accounts: {
                        [i]: {$merge: { account_name, logo_url }}
                    },
                },
                currentAccount: {$merge: updatedAccount},
            });

        /* Users */
        case types.FETCH_USER_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                details: payload,
            });

        /* Publications */
        case types.CREATE_PUBLICATION_SUCCESS:
            const accIdx2 = state.details.accounts.findIndex(o => o.account_id === payload.account_uid);

            return update(state, {
                details: {
                    accounts: {
                        [accIdx2]: {
                            publications: {$push: [payload.publication] }
                        }
                    },
                },
            });

        case types.UPDATE_PUBLICATION_SUCCESS:
            const accIdx3 = state.details.accounts.findIndex(o => o.account_id === payload.account_uid);
            const pubIdx = state.details.accounts[accIdx3].publications.findIndex(o => o.id === payload.id);

            return update(state, {
                details: {
                    accounts: {
                        [accIdx3]: {
                            publications: {
                                [pubIdx]: {$set: payload}
                            }
                        }
                    },
                },
            });

        /* Subscriptions */
        case types.FETCH_CARDS_SUCCESS:
            return update(state, {
                paymentCards: {
                    [payload.type]: {$set: payload.cards},
                }
            });

        case types.DELETE_CARD_SUCCESS :
            return update(state, {
                paymentCards: {
                    [payload.type]: {$set: state.paymentCards[payload.type].filter(o => `${o.id}` !== `${payload.id}`)},
                }
            });

        /* Logout */
        case authTypes.LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};
