// Core
import { useLayoutEffect } from "react";

// Instruments
import { initDefaultRedactor } from "../../../helpers/helpers";

export const BloggingOneOff = ({ fields }) => {
    /* Hooks */
    const {
        isDetails,

        getFormatField,
        getBanner,
        getIndustryField,
        getQuantityField,
        getLevelField,
        getTopicWritingField,
        getBriefField,
        getWritersField,
        getTurnaroundField,
        getOrderOnField,
        getAdditionalDetailsLabel,
        getLocalizationField,
        getTagsField,
        getCopyStyleVoiceField,
        getAdditionalDetailsFields,
        getIsAllTopicsOneDayCheckbox,

        onRedactorSynced,
        onRedactorBlur
    } = fields;

    useLayoutEffect(() => {
        initDefaultRedactor('#blogging-brief', 'brief', null, null, onRedactorSynced, onRedactorBlur, true);

        return () => {
            window.$R('#blogging-brief', 'destroy');
        };
    }, []);


    return <>
        { getBanner('seobrief') }
        { getIndustryField() }
        { getQuantityField() }
        { getLevelField() }
        { getTopicWritingField() }
        { getBriefField(true) }
        { getWritersField() }
        { getTurnaroundField() }
        { getOrderOnField() }
        { getIsAllTopicsOneDayCheckbox() }
        { getAdditionalDetailsLabel() }
        { getLocalizationField() }
        { isDetails && <>
            { getTagsField() }
            { getCopyStyleVoiceField(null) }
            { getAdditionalDetailsFields() }
        </> }
    </>;
};
