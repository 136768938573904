// Core
import { useLocation } from 'react-router-dom';

// Components
import { Desktop } from "./Desktop";
import { Mobile } from "./Mobile";

// Hooks
import { useUi } from "../../hooks/useUi";

// Instruments
import { includes } from "ramda";
import { isEpiic } from "../../helpers/helpers";

export const Header = () => {
    const BLOCKED_PATHNAMES = [
        '/login',
        '/password',
        '/password-update',
        '/password-create',
        '/company/new',
        '/company/new/',
        '/designers/plans',
        '/designers/pricing',
        '/website-developers/pricing',
        '/writers/pricing',
        '/bloggers/pricing',
        '/bundles',
        '/prepay',
        '/prepay-bank',
        '/payment/confirm',
        '/payment/success',
        '/assistants/pricing',
        '/hourly-designers/pricing',
        '/motion/pricing',
        '/support/pricing',
        '/designers-unlimited/pricing'
    ];
    const { isMobile } = useUi();
    const { pathname } = useLocation();

    if (includes(pathname, BLOCKED_PATHNAMES)) return null;
    if (isEpiic(null) && includes('/apply/designers', pathname)) return null; //['/apply/designers/ru/success', '/apply/designers/ru', '/apply/designers/success', '/apply/designers']

    return isMobile ? <Mobile /> : <Desktop />;
};