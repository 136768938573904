
// Components
import {loadStripe} from "@stripe/stripe-js";

// Hooks
import {usePayments} from "../../hooks/usePayments";

// Instruments
import {_debug, _log} from "../../helpers/helpers";
import {epiic, stripeKey, stripeKeyEpiic} from "../../helpers/api";
import {useUi} from "../../hooks/useUi";
import {isNil} from "ramda";
import {useNavigate} from "react-router-dom";

export const StripeCustom = (platform) => {

    /* State */
    const { setPaymentsState } = usePayments();
    const { setUiState } = useUi();

    const navigate = useNavigate();

    const stripeTest = (platform) => {
        _log(`stripeTest function. Platform: ${platform}`);
        const stripe = stripeClient(platform);
        _log(stripe);
    };

    const stripeClient = (platform) => {
        const stripeClientKey = platform === epiic ? stripeKeyEpiic : stripeKey;
        return loadStripe(stripeClientKey);
    };

    const collectBankAccount = async (platform, clientSecret, account) => {
        try {
            const stripe = await stripeClient(platform);
            await stripe.collectBankAccountForPayment({
                clientSecret: clientSecret,
                params: {
                    payment_method_type: 'us_bank_account',
                    payment_method_data: {
                        billing_details: {
                            name: account.account_name,
                            email: account.billing_email,
                        },
                    },
                },
                expand: ['payment_method'],
            }).then(({paymentIntent, error}) => {

                _debug('Stripe collectBankAccountForPayment. paymentIntent received: ');
                _debug(paymentIntent);
                setPaymentsState('prepayBankStripeShowMandate', false);

                if (error) {

                    // Error
                    _debug(`paymentIntent error: ${error.message}`);
                    setUiState('notification', {msg: error.message, type: 'success'});

                } else if (paymentIntent.status === 'requires_source') {

                    // Customer closed dialog right after opening
                    _debug(`paymentIntent.status: requires_source`);
                    setUiState('notification', {msg: 'Please link your account11', type: 'error'});

                } else if (paymentIntent.status === 'requires_payment_method') {

                    // Customer canceled the hosted verification modal. Present them with other payment method type options.
                    _debug(`paymentIntent.status: requires_payment_method`);

                } else if (paymentIntent.status === 'requires_confirmation') {

                    // We collected an account - possibly instantly verified, but possibly
                    // manually-entered. Display payment method details and mandate text
                    // to the customer and confirm the intent once they accept
                    // the mandate.
                    _debug(`paymentIntent.status: requires_confirmation`);
                    setPaymentsState('prepayBankStripeShowMandate', true);
                }
            });
        } catch (err) {
            _debug(`collectBankAccount catch error: ${err}`);
            setUiState('notification', {msg: err, type: 'error'});
        }
    }

    const confirmUsBankAccountPayment = async (platform, clientSecret, redirectUrl) => {
        try {
            const stripe = await stripeClient(platform);
            await stripe.confirmUsBankAccountPayment(clientSecret)
                .then(({paymentIntent, error}) => {

                    _debug('Stripe confirmUsBankAccountPayment. paymentIntent received: ');
                    _debug(paymentIntent);
                    setPaymentsState('prepayBankStripeShowMandate', false);

                    if (error) {

                        // Error
                        _debug(`paymentIntent error: ${error.message}`);
                        setUiState('notification', {msg: error.message, type: 'error'});

                    } else if (paymentIntent.status === 'requires_source') {

                        // Customer closed dialog right after opening
                        _debug(`paymentIntent.status: requires_source`);
                        setUiState('notification', {msg: 'Please link your account', type: 'error'});

                    } else if (paymentIntent.status === 'requires_payment_method') {

                        // Customer canceled the hosted verification modal. Present them with other payment method type options.
                        _debug(`paymentIntent.status: requires_payment_method`);

                    } else if (paymentIntent.status === 'requires_confirmation') {
                        // We collected an account - possibly instantly verified, but possibly
                        // manually-entered. Display payment method details and mandate text
                        // to the customer and confirm the intent once they accept
                        // the mandate.

                        _debug(`paymentIntent.status: requires_confirmation`);
                    } else if (paymentIntent.status === 'processing') {

                        setPaymentsState('prepayBankStripeSuccessMessage', 'Your debit request has been processed successfully. Please allow up to 4 days to settle');
                        if(!isNil(redirectUrl)) {
                            navigate(redirectUrl, { replace: true });
                        }

                        //setUiState('notification', {msg: 'Your payment has been received. Please allow up to 4 days to settle', type: 'success'});
                    }
                });
        } catch (err) {
            _debug(`confirmUsBankAccountPayment catch error: ${err}`);
            setUiState('notification', {msg: err, type: 'error'});
        }
    }

    return {
        stripeTest,
        collectBankAccount,
        confirmUsBankAccountPayment
    };

};
