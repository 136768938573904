// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, authStr, isStage, getKeys, getPusherData, getCurrencies, getCompanyBranding, getBundlesRef, getUserDetails } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { paymentsActions } from '../../../payments/actions';

export function* callPrepayBankWorker ({ payload: { data: obj, navigate } }) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey } = yield select(getKeys);
        const { sessionID } = yield select(getPusherData);
        const response = yield call(fetch, `${api}/${publicKey}/v1/stripe/payment-intent/bank-debit`,{
            method: 'POST',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                ...obj,
                pusherData: {
                    sessionID
                },
            }),
        });

        const { response : data, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        if ( obj.payment_method === 'stripe' ) {
            yield put(paymentsActions.setPaymentsState('prepayBankStripe', {
                client_secret: data.client_secret
            }));
        }

    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
        if ( obj.payment_method === 'stripe' ) {
            yield put(uiActions.disableFetching());
            yield put(uiActions.setUiState('isBlocking', false));
        }
    } finally {
        yield put(uiActions.disableFetching());
        if ( obj.payment_method !== 'stripe' ) {
            yield put(uiActions.setUiState('isBlocking', false));
        }
    }
}
