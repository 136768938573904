// Core
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { orderActions } from "../bus/order/actions";
import { orderActionsAsync } from "../bus/order/saga/asyncActions";

export const useOrder = () => {
    const selector = (state) => state.order;
    const order = useSelector(selector);
    const dispatch = useDispatch();

    /* Actions */
    const setOrderState = (prop, value) => {
        dispatch(orderActions.setOrderState(prop, value));
    };
    const updateOrderState = (data) => {
        dispatch(orderActions.updateOrderState(data));
    };

    /* Async actions */
    const orderPayAsync = (data, navigate) => {
        dispatch(orderActionsAsync.orderPayAsync(data, navigate));
    };
    const bundlesPayAsync = (data, navigate) => {
        dispatch(orderActionsAsync.bundlesPayAsync(data, navigate));
    };
    const addProjectToOrderAsync = (id, action, navigate) => {
        dispatch(orderActionsAsync.addProjectToOrderAsync(id, action, navigate));
    };
    const removeProjectFromOrderAsync = (id, navigate) => {
        dispatch(orderActionsAsync.removeProjectFromOrderAsync(id, navigate));
    };
    const prepayBankPayAsync = (data, navigate) => {
        dispatch(orderActionsAsync.prepayBankPayAsync(data, navigate));
    };

    return {
        ...order,
        setOrderState,
        updateOrderState,
        bundlesPayAsync,
        orderPayAsync,
        addProjectToOrderAsync,
        removeProjectFromOrderAsync,
        prepayBankPayAsync
    };
};
